import styled, {keyframes} from 'styled-components';

export const GotBack = styled.div`
  width: 100%;
  min-height: 50vh;
  background: ${({theme}) => theme.palatte.background.contrast};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 24px;
  padding-top: 100px;

  & > h1 {
    font-size: 6rem;
    line-height: 1.6;
    letter-spacing: 0.1rem;
    font-family: Thicccboi, sans-serif;

    span {
      color: ${({theme}) => theme.palatte.background.highlight};
    }
  }

  @media (max-width: 768px) {
    height: auto;

    & > h1 {
      margin: 0px;
      padding: 24px 8px;
      font-size: 4rem;
      line-height: 1.2;
      letter-spacing: 0.2rem;
    }
  }
`;

export const ServiceBoxes = styled.div`
  display: flex;
  max-width: 1200px;
  justify-content: space-around;
  align-items: center;
  padding: 0px 16px;
  margin: 120px auto;

  & > div {
    padding: 24px;
    height: 100%;
    width: 272px;
    min-height: 414px;

    border: 1px solid ${({theme}) => theme.palatte.text.default};
    text-align: center;
    box-shadow: -10px 8px 2px 0px rgba(0, 0, 0, 0.28);

    h2 {
      margin: 0px;
      font-size: 22px;
      font-weight: 600;
      letter-spacing: 0.1rem;
      font-family: Thicccboi, sans-serif;
      line-height: 1.4;
    }

    svg {
      margin: 20px auto;
      height: 94px;
    }

    p {
      text-align: left;
      line-height: 1.6;
      font-size: 1.1em;
      font-weight: 500;
      font-family: Thicccboi, sans-serif;
      opacity: 0.8;
    }
  }

  @media (max-width: 1024px) {
    gap: 16px;
    & > div {
      height: 440px;
      margin-bottom: 24px;
      width: 256px;
    }
  }
  @media (max-width: 768px) {
    flex-direction: column;
    margin: 60px auto;

    & > div {
      margin-bottom: 24px;
    }
  }
`;

export const Description = styled.div`
  padding: 80px 120px;
  background-color: ${({theme}) => theme.palatte.text.default};
  color: #fff;
  line-height: 2rem;
  font-size: 1.4rem;
  letter-spacing: 0.05rem;

  div {
    margin: auto;
    max-width: 1000px;

    p {
      font-family: Thicccboi, sans-serif;
      margin: 2em 0;
      font-weight: 500;
    }
  }

  @media (max-width: 768px) {
    padding: 40px 24px;
  }
`;

export const ServiceListWrapper = styled.div`
  margin: 120px auto;
  max-width: 1200px;

  h1 {
    text-align: center;
    margin-bottom: 40px;
    font-weight: 600;
    font-family: Thicccboi, sans-serif;

    span {
      color: ${({theme}) => theme.palatte.background.highlight};
    }
  }

  div {
    display: flex;

    @media (max-width: 768px) {
      flex-wrap: wrap;
      justify-content: center;
    }

    div {
      margin: 24px 16px;
      width: 260px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      div {
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        width: 100px;
        height: 100px;
        border-radius: 50px;
        margin-bottom: 8px;
        display: flex;
        justify-content: center;
      }

      h3 {
        font-weight: 600;
      }

      p {
        margin: 0px;
        line-height: 1.6;
      }
    }
  }
`;

export const ClientWrapper = styled.div`
  background: ${({theme}) => theme.palatte.background.contrast};
  align-items: center;
  display: flex;
  min-height: 620px;
  padding: 0px 24px;

  & > div {
    margin: auto;
    max-width: 1200px;
    display: flex;
    height: 100%;
    align-items: center;

    @media (max-width: 768px) {
      flex-direction: column;
      padding: 40px 0px;
    }

    .image-wrapper {
      max-width: 500px;
      max-height: 385px;
      width: 100%;
      height: 100%;
      // background-color: ${({theme}) => theme.palatte.colors.yellow};;
      border-radius: 24px;
      overflow: hidden;
      padding: 16px;
      display: flex;
    }

    .image-wrapper img {
      border-radius: 24px;
      object-fit: cover;
      width: calc(100%);
    }

    .details {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: left;
      width: 100%;
      max-width: 500px;
      margin-left: 32px;

      h2 {
        font-family: Thicccboi, sans-serif;
      }

      p {
        line-height: 1.6;
      }
    }
  }
`;

export const MoreInfo = styled.a`
  text-align: right;
  margin-right: 32px;
  margin-top: 32px;
  align-items: center;
  display: flex;
  align-self: flex-end;
  text-decoration: none;
  color: ${({theme}) => theme.palatte.text.default};

  & > div {
    margin-left: 8px;
    height: 40px;
    width: 40px;
    background-color: ${({theme}) => theme.palatte.text.default};
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &:hover {
    cursor: pointer;
  }
`;
