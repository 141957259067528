import React from 'react';
import { ClientWrapper, MoreInfo } from './styled';
import MoreInfoIcon from '../../images/svgs/more-info.svg';

const ServiceClient = () => {

  return (
    <ClientWrapper>
      <div>
        <div className="image-wrapper">
          <img src='/images/projects/netlise/netlise-pos.png' />
        </div>

        <div className="details">
          <h2 className=' text-4xl font-bold my-6'>Netlise Platform</h2>
          <p>
            Process orders with online and offline capabilities. Access you Point of Sale operations from your machine, iPad and touch terminal.
            Syncs all the restaurant operations and back of the house ops in one platform.
          </p>
          <p>
            View reports and activities from dashboard and get insites on productivity trends.
          </p>
          <MoreInfo href="/projects/netlise" color="blue">
            <span>More Info</span>
            <div>
              <MoreInfoIcon />
            </div>
          </MoreInfo>
        </div>
      </div>
    </ClientWrapper>
  )
}

export default ServiceClient;