import React from "react";
import { Description, GotBack, ServiceBoxes } from "./styled";
import { LetsTalk } from '../../components/Slider/Slide1/styled';
import LetsTalkIcon from '../../images/svgs/lets-talk.svg';
import ServiceList from './ServiceList';
import ResearchIcon from '../../images/svgs/research.svg';
import DevIcon from '../../images/svgs/develop.svg';
import LaunchIcon from '../../images/svgs/launch.svg';
import ServiceClient from "./ServiceClient";
import { ContactContext } from "../../internal/context/ContactContext";
import { useContext } from "react";

const Services = () => {
  const { setShowModal } = useContext(ContactContext);
  return (
    <div>
      <GotBack>
        <h1 className="font-bold">If you have a <span>vision</span>, we<br /> got your <span>back</span>
        <LetsTalk style={{justifyContent: 'flex-start', marginTop: '32px'}}>
          <span>Let's Talk</span>
          <div onClick={() => setShowModal(true)}>
            <LetsTalkIcon />
          </div>
        </LetsTalk>
        </h1>
      </GotBack>

      <ServiceBoxes>
        <div>
          <h2>Research</h2>
          <ResearchIcon />
          <p>
            Research and analyse the requirements for the application development. Document the initial phase for the implementation and setup milestones to deliver in stages
          </p>
        </div>
        <div>
          <h2>Develop</h2>
          <DevIcon />
          <p>
            Develop platforms with agile methodologies and continuously follow feedback to improve products to reach best quality standards
          </p>
        </div>
        <div>
          <h2>Launch and monitor </h2>
          <LaunchIcon />
          <p>Deployment of environments and monitor the application for support and analytics to improve product experience to the best level</p>
        </div>
      </ServiceBoxes>
      <Description>
        <div>
          <p>From architecture to solution we will be full filling gaps with latest technologies
            and methodologies. The key to our successful application is re imagination of
            platforms with market strategies and combining it with technology.</p>
          <p>
            Provide us with your idea in your mind. We will carefully analyse it and outline to a workable set of requirements later can be converted to epics and stories.</p>
          <p>We create complex products, from the initial idea and formulation of product strategy, through building a prototype and testing it with users, right to the creation of the product itself.</p>
        </div>
      </Description>
      <ServiceList />
      <ServiceClient />
    </div>
  )
}

export default Services;