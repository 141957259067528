import * as React from 'react';
import Layout from '../components/Layout';
import Services from '../modules/services/Services';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet';
import ContactForm from '../modules/contact/ContactForm';

const ServicesPage = () => {
  return (
    <Layout>
      <Helmet>
        <meta name="description" content="Software engineering and product growth services provided by Zegates" />
        <title>Engineering and Product Growth Services - Zegates International</title>
      </Helmet>
      <ContactForm />
      <Services />
      <Footer />
    </Layout>
  );
};

export default ServicesPage;
